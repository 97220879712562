import Vue from 'vue';
import Vuex from 'vuex';

import client from './modules/client';
import search from './modules/search';
import employee from './modules/employee';
import profile from './modules/profile';
import order from './modules/order';

import API from '@/api';
import { ToastProgrammatic as Toast } from 'buefy';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    getme: {},
    csrf: null,
    loading: true,
    showSidebar: true,
    isModalNeedPayActive: false,
    user: {},
    statistic: {},
  },
  mutations: {
    toggleSidebar(state, fullWidth = false) {
      if (fullWidth) {
        state.showSidebar = true;
      } else {
        state.showSidebar = !state.showSidebar;
      }
    },
    toggleLoader(state, load) {
      state.loading = load;
    },
    toggleModalNeedPay(state, active) {
      state.isModalNeedPayActive = active;
    },
    setCSRF(state, csrf) {
      state.csrf = csrf;
    },
    setGetme(state, getme) {
      state.getme = getme;
    },
    setUser(state, data) {
      state.user = data;
    },
    setStatistic(state, data) {
      state.statistic = data;
    },
  },
  actions: {
    async getme({ commit }) {
      const getme = await API.getme();

      if ('redirect' in getme) {
        return;
      }

      if ('success' in getme && !getme.success) {
        commit('toggleModalNeedPay', true);
        commit('setGetme', getme);

        return;
      }

      commit('setGetme', getme);
      commit('setUser', getme.user);
      commit('setCSRF', getme.csrf);
    },
    async logout() {
      await API.logout();
    },
    async profileInfo({ commit }) {
      const data = await API.profileInfo();

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf);
      }

      if ('user' in data) {
        commit('setGetme', data);
        commit('setUser', data.user);
      }

      if ('employees' in data.user) {
        commit('employee/setEmployees', data.user.employees);
      }
    },
    async getStatistic({ state, commit, dispatch }) {
      const data = await API.getStatistic({ csrf: state.csrf });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        return;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null);
        dispatch('getStatistic');
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf);
      }

      if (data.success) {
        commit('setStatistic', {
          totalAmount: data.totalAmount,
          totalKv: data.totalKv,
        });
      }
    },
  },
  modules: {
    client,
    search,
    employee,
    profile,
    order,
  },
});
