<template>
  <b-table
    class="search-table"
    ref="table"
    detailed
    detail-key="orderId"
    :show-detail-icon="false"
    :opened-detailed="details"
    :data="tableData"
    :bordered="true"
    :striped="true"
    :narrowed="false"
    :hoverable="true"
    :loading="false"
    :focusable="false"
    :mobile-cards="true"
    :scrollable="true"
  >
    <b-table-column field="orderId" label="Номер заказа" v-slot="props">
      {{ props.row.orderId }}
    </b-table-column>

    <b-table-column field="cadNumber" label="Кадастровый номер" v-slot="props">
      {{ props.row.cadNumber }}
    </b-table-column>

    <b-table-column
      field="createdAt"
      label="Дата заказа"
      centered
      v-slot="props"
    >
      {{ formatDateTime(props.row.createdAt) }}
    </b-table-column>

    <b-table-column
      field="doneAt"
      label="Дата выполнения"
      centered
      v-slot="props"
    >
      {{ formatDateTime(props.row.doneAt) }}
    </b-table-column>

    <b-table-column field="isPayed" label="Оплачен" centered v-slot="props">
      {{ props.row.isPayed ? 'Да' : 'Нет' }}
    </b-table-column>

    <b-table-column field="refund" label="Возврат" centered v-slot="props">
      {{ props.row.refund ? formatDateTime(props.row.refundedAt) : 'Нет' }}
      <br />
      {{ props.row.refund ? '(' + props.row.refundType + ')' : '' }}
    </b-table-column>

    <b-table-column field="isDone" label="Завершен" centered v-slot="props">
      {{ props.row.isDone ? 'Да' : 'Нет' }}
    </b-table-column>

    <b-table-column field="isCancelled" label="Отменен" centered v-slot="props">
      {{ props.row.isCancelled ? 'Да' : 'Нет' }}
    </b-table-column>

    <b-table-column field="free" label="Бесплатный" centered v-slot="props">
      {{ props.row.free ? 'Да' : 'Нет' }}
    </b-table-column>

    <b-table-column
      field="isReordered"
      label="Перезаказан"
      centered
      v-slot="props"
    >
      {{
        props.row.isReordered ? formatDateTime(props.row.reorderedAt) : 'Нет'
      }}
    </b-table-column>

    <template #detail="props">
      <div class="details-type">
        <p><b>Вид отчета:</b> {{ typeDictionary(props.row.type) }}</p>
      </div>
      <div class="detail-btns">
        <div class="action-btns" v-if="props.row.isPayed">
          <b-button
            size="is-small"
            type="is-success"
            v-if="props.row.isDone && !props.row.isCancelled"
            @click.prevent="sendRepeat(props.row.orderId, props.row.project)"
            >Отправить отчет</b-button
          >

          <b-button
            size="is-small"
            type="is-info"
            v-if="props.row.isDone && !props.row.isCancelled"
            @click.prevent="viewReport(props.row.orderId, 'pdf', props.row.project)"
            >Посмотреть отчет (pdf)</b-button
          >

          <b-button
            size="is-small"
            type="is-danger"
            v-if="props.row.toCancel && !props.row.isDone && !props.row.refund"
            @click.prevent="cancelOrder(props.row.orderId, props.row.project)"
            >Отменить заказ</b-button
          >

          <b-button
            size="is-small"
            :type="props.row.reorderNotAllowed ? 'is-link' : 'is-danger'"
            v-if="!props.row.isReordered && !props.row.isDone"
            @click.prevent="reorderNotAllowed(props.row.orderId, props.row.project)"
          >
            {{
              props.row.reorderNotAllowed
                ? 'Разрешить перезаказ'
                : 'Запретить перезаказ'
            }}
          </b-button>

          <b-button
            size="is-small"
            type="is-danger"
            v-if="props.row.toRefund && $store.state.user.role == 1"
            @click.prevent="toRefund(props.row.orderId, props.row.project)"
            >Возврат</b-button
          >
        </div>
      </div>
    </template>

    <template #empty>
      <div class="has-text-centered">
        <b-icon icon="ban" size="is-medium" type="is-danger"></b-icon>
        <b>Ничего не найдено</b>
      </div>
    </template>
  </b-table>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'TableMainData',
  props: ['clientData', 'orders'],
  data() {
    return {
      tableData: this.orders || [],
    };
  },
  computed: {
    details() {
      return this.tableData.map((item) => item.orderId);
    },
  },
  watch: {
    orders(data) {
      this.tableData = data;
    },
  },
  methods: {
    typeDictionary(type) {
      let text = '';

      switch (type) {
        case 'XZP':
          text =
            'Отчет об основных параметрах объекта недвижимости, в том числе проверка собственника (собственников), ограничение прав и обременения';
          break;

        case 'SOPP':
          text = 'Отчет об изменениях прав на объект недвижимости';
          break;

        case 'SKS':
          text =
            'Отчет об установлении и/или изменении кадастровой стоимости объекта недвижимости';
          break;

        case 'CHECK':
          text = 'Проверки недвижимости на риски';
          break;

        case 'KPT':
          text =
            'Отчет об объектах недвижимости в пределах кадастрового плана территории';
          break;

        default:
          break;
      }

      return text;
    },
    formatDateTime(date) {
      if (date.length) {
        return dayjs(date).format('DD.MM.YYYY HH:mm');
      }

      return '-';
    },
    viewReport(id, type, project) {
      window.open(
        `https://${project}/api/get-egrn/${type}?email=${this.clientData.email}&order_id=${id}`,
        '_blank'
      );
    },
    async sendRepeat(id, project) {
      const success = await this.$store.dispatch('order/sendRepeat', {id, project});

      if (success) {
        this.refreshData();
      }
    },
    async cancelOrder(id, project) {
      const success = await this.$store.dispatch('order/cancelOrder', {id, project});

      if (success) {
        this.refreshData();
      }
    },
    async toRefund(id, project) {
      const success = await this.$store.dispatch('order/toRefund', {id, project});

      if (success) {
        this.refreshData();
      }
    },
    async reorderNotAllowed(id, project) {
      const success = await this.$store.dispatch('order/reorderNotAllowed', {id, project});

      if (success) {
        this.refreshData();
      }
    },
    async refreshData() {
      const searchData = Object.freeze(this.$store.state.search.searchData);

      if (
        searchData.email.length &&
        searchData.email !== this.clientData.email
      ) {
        searchData.email = this.clientData.email;
      }

      await this.$store.dispatch('search/searching', searchData);
    },
  },
};
</script>

<style lang="scss">
.search-table {
  .detail {
    td {
      padding: 0;

      .detail-container {
        padding: 10px !important;
      }
    }
  }

  .details-type {
    margin-bottom: 10px;
    border-bottom: 2px solid #dbdbdb;
    padding-bottom: 5px;
  }

  .action-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 100%;
      max-width: 250px;
      gap: 0 10px;
    }

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 10px 0;
    }
  }

  @media screen and (max-width: 1200px) {
    font-size: 14px;
  }

  @media screen and (max-width: 640px) {
    font-size: 13px;
  }

  @media screen and (max-width: 380px) {
    font-size: 12px;
  }
}
</style>
