import Vue from 'vue';
import VueRouter from 'vue-router';
import Workspace from '../views/Workspace.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    component: () => import('../views/Page404.vue'),
    meta: {
      layout: 'GuestLayout',
    },
  },
  {
    path: '/',
    name: 'Workspace',
    component: Workspace,
  },
  {
    path: '/admin',
    redirect: '/admin/profile',
  },
  {
    path: '/admin/profile',
    name: 'AdminProfileInfo',
    component: () => import('../views/AdminProfileInfo.vue'),
    meta: {
      layout: 'AdminLayout',
    },
  },
  {
    path: '/admin/employees',
    name: 'AdminEmployees',
    component: () => import('../views/AdminEmployees.vue'),
    meta: {
      layout: 'AdminLayout',
    },
  },
  {
    path: '/admin/statistic',
    name: 'AdminStatistic',
    component: () => import('../views/AdminStatistic.vue'),
    meta: {
      layout: 'AdminLayout',
    },
  },
  {
    path: '/statistic',
    name: 'UserStatistic',
    component: () => import('../views/UserStatistic.vue'),
    meta: {
      layout: 'ProfileLayout',
    },
  },
  {
    path: '/create-employee',
    name: 'NewEmployee',
    component: () => import('../views/NewEmployee.vue'),
    meta: {
      layout: 'CreateLayout',
    },
  },
  {
    path: '/clients/:id',
    name: 'Client',
    component: () => import('../views/Client.vue'),
    meta: {
      layout: 'CreateLayout',
    },
  },
  {
    path: '/user',
    redirect: '/user/profile',
  },
  {
    path: '/user/profile',
    name: 'UserProfile',
    component: () => import('../views/UserProfile.vue'),
    meta: {
      layout: 'ProfileLayout',
    },
  },
  {
    path: '/search-cadnumber',
    name: 'SearchCadNumber',
    component: () => import('../views/SearchCadNumber.vue'),
    meta: {
      layout: 'CreateLayout',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/restore-password',
    name: 'RestorePassword',
    component: () => import('../views/RestorePassword.vue'),
    meta: {
      layout: 'AuthLayout',
    },
  },
  // {
  //   path: '/sign-up',
  //   name: 'SignUp',
  //   component: () => import('../views/SignUp.vue'),
  //   meta: {
  //     layout: 'AuthLayout',
  //   },
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
