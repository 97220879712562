<template>
  <div class="workspace">
    <div class="has-text-centered" v-if="!tableMainData.length">
      <b-icon icon="search" size="is-medium" type="is-primary"></b-icon>
      <b>Здесь будут отображаться результаты поиска</b>
    </div>

    <ClientInfo
      v-if="tableMainData.length"
      :searchingResult="searchingResult"
    />
    <hr />
    <div class="tables-block" v-if="tableMainData.length">
      <TableMain
        v-for="orders in arrTableMainData"
        :orders="orders"
        :clientData="searchingResult.clientData"
        :key="orders.orderId"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TableMain from '@/components/workspace/TableMain.vue';
import ClientInfo from '@/components/workspace/ClientInfo.vue';

export default {
  name: 'Workspace',
  data() {
    return {};
  },
  async created() {
    this.$store.commit('toggleLoader', true);
    await this.$store.dispatch('getme');
    this.$store.commit('toggleLoader', false);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.resizeWindow);
      this.resizeWindow();
    });
  },
  computed: {
    ...mapState({
      searchingResult: (state) => {
        return state.search.searchingResult;
      },
    }),
    tableMainData() {
      return this.searchingResult.orders ? this.searchingResult.orders : [];
    },
    arrTableMainData() {
      return this.tableMainData.map((item) => [item]);
    },
  },
  methods: {
    resizeWindow() {
      if (!window.matchMedia('(max-width: 960px)').matches) {
        this.$store.commit('toggleSidebar', true);
      }
    },
  },
  components: { TableMain, ClientInfo },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeWindow);
  },
};
</script>

<style lang="scss">
.workspace {
  .tables-block {
    .search-table {
      margin-bottom: 30px;
    }
  }
}
</style>
