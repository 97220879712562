import API from '@/api';
import { ToastProgrammatic as Toast } from 'buefy';

const search = {
  namespaced: true,
  state: () => ({
    searchData: {},
    searchingResult: {},
    searchByCadNumberResult: {},
  }),
  mutations: {
    setSearchingResult(state, result) {
      state.searchingResult = result;
    },
    setSearchData(state, data) {
      state.searchData = data;
    },
    setSearchByCadNumber(state, data) {
      state.searchByCadNumberResult = data;
    },
  },
  actions: {
    async searching({ rootState, commit, dispatch }, orderData) {
      commit('toggleLoader', true, { root: true });
      commit('setSearchingResult', {});
      commit('setSearchData', orderData);

      const data = await API.search({
        orderData,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('searching', orderData);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (data.success && 'result' in data) {
        commit('setSearchingResult', data.result);
      } else {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });

        commit('setSearchingResult', {});
      }

      commit('toggleLoader', false, { root: true });
    },
    async searchByCadNumber({ rootState, commit, dispatch }, cadNumber) {
      commit('toggleLoader', true, { root: true });

      const data = await API.searchByCadNumber({
        cad_number: cadNumber,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('setSearchByCadNumber', {});
        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('searchByCadNumber', cadNumber);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (data.success && 'result' in data) {
        commit('setSearchByCadNumber', data.result);
      } else {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });

        commit('setSearchByCadNumber', {});
      }

      commit('toggleLoader', false, { root: true });

      return data.success;
    },
  },
  getters: {},
};

export default search;
