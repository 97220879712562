<template>
  <section class="panel is-primary">
    <h1 class="title is-6 panel-heading mb-0 has-text-centered">ПРОЕКТ - {{ clientData.project }}</h1><br>
    <h1 class="title is-6 panel-heading mb-0">Информация о клиенте</h1>
    <div class="client-info box">
      <div class="columns">
        <div class="column">
          <h6>Телефон:</h6>
          <p>{{ clientData.phone }}</p>
        </div>
        <div class="column">
          <h6>Подписка:</h6>
          <p>{{ clientData.subscription.exist ? 'Да' : 'Нет' }}</p>
        </div>
        <div class="column" v-if="clientData.subscription.exist">
          <h6>Статус подписки:</h6>
          <p>{{ clientData.subscription.unsub}}</p>
        </div>
        <div class="column" v-if="clientData.subscription.exist">
          <h6>Дата отмены:</h6>
          <p>{{ clientData.subscription.unsubDate}}</p>
        </div>
        <div class="column">
          <h6>Email:</h6>
          <div class="change-client-email">
            <div class="change-client-email__text" v-if="!showField">
              <p>
                {{ clientData.email }}
              </p>
              <b-button
                type="is-info"
                size="is-small"
                @click.prevent="showField = !showField"
                >Изменить Email</b-button
              >
            </div>
            <div class="change-client-email__field" v-else>
              <b-input v-model="newEmail"></b-input>
              <b-button type="is-info" @click.prevent="changeEmail"
                >Изменить Email</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="client-info box" v-if="clientData.subscription.exist">
      <h3 class="title is-6 panel-heading mb-0 has-text-centered">Информация о списаниях</h3>
      <div class="columns" v-for="subscription in clientData.subscription.subscriptions">
        <div class="column">
          <h6>Номер подписки:</h6>
          <p>{{ subscription.id }}</p>
        </div>
        <div class="column">
          <h6>Дата</h6>
          <p>
            {{ subscription.createdAt }}
          </p>
        </div>
        <div class="column">
          <h6>Статус</h6>
          <p>
            {{ subscription.status }}
          </p>
        </div>
        <div class="column">
          <h6>Возврат</h6>
          <p>
            {{ subscription.isRefund ? 'Да' : 'Нет' }}
          </p>
        </div>
        <div class="column" v-if="subscription.isRefund">
          <h6>Дата возврата</h6>
          <p>
            {{ subscription.refundedAt }}
          </p>
        </div>
        <div class="column" :id="'check' + subscription.id" v-if="subscription.isRefund">
          <b-button
              size="is-small"
              type="is-warning"
              @click.prevent="toGetKktCheck(subscription.id)"
          >Чек</b-button
          >
        </div>
        <div class="column" v-if="!subscription.isRefund
        && subscription.status ==='Charged'
        && $store.state.user.role === 1">
          <h6>Сделать возврат</h6>
          <b-button
              size="is-small"
              type="is-danger"
              @click.prevent="toRefundSub(subscription.id)"
          >Возврат</b-button
          >
        </div>
      </div>
    </div>

    <div class="client-info box" v-if="clientData.subscriptions !== undefined">
      <h3 class="title is-6 panel-heading mb-0 has-text-centered">Результат поиска по номеру карты</h3>
      <div class="columns" v-for="subscription in clientData.subscriptions">
        <div class="column">
          <h6>Номер подписки:</h6>
          <p>{{ subscription.id }}</p>
        </div>
        <div class="column">
          <h6>Дата</h6>
          <p>
            {{ subscription.createdAt }}
          </p>
        </div>
        <div class="column">
          <h6>Статус</h6>
          <p>
            {{ subscription.status }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ClientInfo',
  props: ['searchingResult'],
  data() {
    return {
      showField: false,
      clientData: this.searchingResult.clientData || {},
      newEmail: this.searchingResult.clientData.email || '',
    };
  },
  watch: {
    'searchingResult.clientData.email'(email) {
      this.newEmail = email;
    },
    'searchingResult.clientData'(data) {
      this.clientData = data;

    }
  },
  methods: {
    async changeEmail() {
      const success = await this.$store.dispatch('order/changeEmail', {
        email: this.clientData.email,
        newEmail: this.newEmail,
        id: this.clientData.id,
        project: this.clientData.project,
      });

      if (success) {
        this.showField = false;
        const newResult = Object.freeze({ ...this.searchingResult });
        newResult.clientData.email = this.newEmail;

        this.$store.commit('search/setSearchingResult', newResult);
      }
    },
    async toRefundSub(id){
      const success = await this.$store.dispatch('order/toRefundSub', {id});

      if (success) {
        this.refreshData();
      }
    },
    async toGetKktCheck(id){
      const res = await this.$store.dispatch("order/toGetKktCheck", { id });

      if (res.success) {
        let eid = 'check'+id;
        let el = window.document.getElementById(eid);
        el.innerHTML = '<a href="' + res.link + '" target="_blank">Скачать чек</a>';
        //window.open(res.link, '_blank');
      }
    }
  },
};
</script>

<style lang="scss">
.client-info {
  h6 {
    font-weight: bold;
  }

  .change-client-email {
    &__text,
    &__field {
      display: flex;
      align-items: center;
      gap: 10px;

      @media screen and (max-width: 640px) {
        flex-direction: column;
        align-items: flex-start;

        button {
          width: 100%;
        }
      }
    }
  }
}
</style>
