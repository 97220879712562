import API from '@/api';
import { ToastProgrammatic as Toast } from 'buefy';

const search = {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async cancelOrder({ rootState, commit, dispatch }, {id, project}) {
      commit('toggleLoader', true, { root: true });

      const data = await API.cancelOrder({
        id, project,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('cancelOrder', id, project);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.success;
    },
    async toRefund({ rootState, commit, dispatch }, {id, project}) {
      commit('toggleLoader', true, { root: true });

      const data = await API.toRefund({
        id, project,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('toRefund', id, project);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.success;
    },
    async toGetKktCheck({ rootState, commit, dispatch }, {id}) {
      commit('toggleLoader', true, { root: true });

      const data = await API.toGetKktCheck({
        csrf: rootState.csrf,
        id
      });

      if (data.status === 500) {
        Toast.open({
          duration: 5000,
          message:
              data.response.data.message ||
              'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if (data.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('toRefundSub', id);
        return;
      }

      if ('csrf' in data.data) {
        commit('setCSRF', data.data.csrf, { root: true });
      }

      if (!data.data.success) {
        Toast.open({
          duration: 5000,
          message: data.data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.data;
    },
    async toRefundSub({ rootState, commit, dispatch }, {id}) {
      commit('toggleLoader', true, { root: true });

      const data = await API.toRefundSub({
        csrf: rootState.csrf,
        id
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
              data.response.data.message ||
              'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('toRefundSub', id);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.success;
    },
    async reorderNotAllowed({ rootState, commit, dispatch }, {id, project}) {
      console.log()
      commit('toggleLoader', true, { root: true });


      const data = await API.reorderNotAllowed({
        id,
        project,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('reorderNotAllowed', id, project);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.success;
    },
    async sendRepeat({ rootState, commit, dispatch }, {id, project}) {
      commit('toggleLoader', true, { root: true });

      const data = await API.sendRepeat({
        id,
        project,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('sendRepeat', id, project);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.success;
    },
    async changeEmail(
      { rootState, commit, dispatch },
      { email, newEmail, id, project }
    ) {
      commit('toggleLoader', true, { root: true });

      const data = await API.changeEmail({
        email,
        newEmail,
        id,
        project,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('changeEmail', { email, newEmail, id, project });
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.success;
    },
  },
  getters: {},
};

export default search;
